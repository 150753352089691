th.MuiTableCell-root,
td.MuiTableCell-root {
  &:first-child {
    padding-left: inherit;
  }

  &:last-child {
    padding-right: inherit;
  }
}

th.MuiTableCell-head {
  line-height: 0;
}

td.body-cell {
  vertical-align: top;
}

td.body-avatar-cell {
  vertical-align: top;
  padding-top: 6px;
  padding-bottom: 6px;

  button {
    padding-top: 0;
    padding-bottom: 0;
  }
}

td.body-phones-cell {
  vertical-align: top;

  & div[class*="phoneItem"] {
    padding: 0;

    &:not(:last-of-type) {
      margin-bottom: 2px;
    }
  }
}

td.body-link-cell {
  vertical-align: top;
  font-size: 14px;

  a {
    padding: 0;
  }

  button {
    line-height: normal;
    font-size: inherit;
  }
}

td.body-checkbox-cell {
  vertical-align: top;
  padding: 12px 0;
}

td.body-tag-cell {
  vertical-align: top;

  & > div {
    min-height: 16.5px;
  }
}

td.body-actions-cell {
  width: 54px;
  text-align: center;
  vertical-align: top;
  padding: 10px 12px;
}

span.MuiTableSortLabel-root {
  padding-top: 0;
}
